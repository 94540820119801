<template>
  <div class="h-100 position-relative map-page">
    <portal v-if="!$route.params.id" to="header-bottom">
      <filters @updated="updatedFilter" />
    </portal>

    <div v-show="ready" class="h-100">
      <fit-height-loader
        v-if="$apollo.queries.venues.loading"
        class="position-absolute"
        show
        style="z-index: 100; background-color: rgba(255, 255, 255, 0.5)"
      />
      <GmapMap
        ref="mapRef"
        :center="{ lat: coordinate.lat, lng: coordinate.lng }"
        :zoom="zoom"
        class="w-100 h-100"
        :options="mapOptions"
        @bounds_changed="debounceFetch"
      >
        <GmapMarker
          v-for="(v, index) in venuesComputed"
          :key="index"
          :position="v.location.coordinates"
          :clickable="true"
          :icon="getMarker(v)"
          @click="onMarkerClick($event, v)"
        />
      </GmapMap>
      <div v-if="pickedVenue" class="bg" @click="pickedVenue = null"></div>
      <transition name="swipe">
        <div
          v-if="pickedVenue"
          v-touch:swipe.bottom="swipe"
          class="position-absolute venue w-100 venue-item mb-4"
        >
          <b-container>
            <venue-list-item
              :venue="pickedVenue"
              :display-menu="false"
              :advertiser-day="advertiserDay"
              view="map"
            />
          </b-container>
        </div>
      </transition>
      <portal to="content">
        <transition name="swipe">
          <router-view />
        </transition>
      </portal>
    </div>
    <fit-height-loader v-if="!ready" />
  </div>
</template>
<script>
import { mapState } from "vuex"
import _debounce from "lodash/debounce"
import VENUES_MAP from "@/graphql/VenuesMap.gql"
import filterable from "@/mixins/filterable"
import FitHeightLoader from "@/components/FitHeightLoader"
import VenueListItem from "../components/VenueListItem"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"

export default {
  components: {
    FitHeightLoader,
    VenueListItem
  },
  mixins: [filterable],
  data() {
    return {
      markers: [],
      zoom: 15,
      ready: true,
      pickedVenue: null,
      extraFilters: [],
      mapOptions: {
        gestureHandling: "cooperative",
        minZoom: 10,
        maxZoom: 18,
        styles: [
          {
            featureType: "poi.business",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ]
      },
      mapping: {
        10: 67
      }
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    venues: {
      query: VENUES_MAP,
      skip: true
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  computed: {
    venuesComputed: function () {
      if (this.venues && this.venues.data) {
        return this.venues.data
      }
      return []
    },
    extraFiltersInput: function () {
      return this.extraFilters.map(filter => {
        return {
          name: filter.name,
          value: filter.value || "",
          values: filter.values || []
        }
      })
    },
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius
    })
  },
  watch: {
    radius: function () {
      this.zoom = this.findCurrentZoom()
    }
  },
  created() {
    this.debounceFetch = _debounce(this.fetch, 500)
  },
  mounted() {
    this.zoom = this.findCurrentZoom()
    this.fetch()
  },
  methods: {
    findCurrentZoom() {
      let minZoom = this.mapOptions.minZoom
      let maxZoom = this.mapOptions.maxZoom
      for (let i = maxZoom; i > minZoom; i--) {
        let gap = i - minZoom
        let radius = this.mapping[minZoom] / Math.pow(2, gap)
        if (radius > this.radius) {
          return i
        }
      }
      return minZoom
    },
    getRadius(map) {
      var bounds = map.getBounds()

      var center = bounds.getCenter()
      var ne = bounds.getNorthEast()

      // r = radius of the earth in statute miles
      var r = 3963.0

      // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
      var lat1 = center.lat() / 57.2958
      var lon1 = center.lng() / 57.2958
      var lat2 = ne.lat() / 57.2958
      var lon2 = ne.lng() / 57.2958

      // distance = circle radius from center to Northeast corner of bounds
      return (
        (r *
          Math.acos(
            Math.sin(lat1) * Math.sin(lat2) +
              Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
          )) /
        1
      )
    },
    fetch() {
      this.$nextTick(() => {
        if (this.pickedVenue) {
          return
        } else {
          this.$refs.mapRef.$mapPromise.then(map => {
            var bounds = map.getBounds()
            if (!bounds) {
              return
            }
            this.$apollo.queries.venues.setVariables({
              perPage: 1000,
              coordinate: {
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng()
              },
              radius: this.getRadius(map),
              extraFilters: this.extraFiltersInput
            })
            this.pickedVenue = null
            this.$apollo.queries.venues.skip = false
            this.$apollo.queries.venues.refetch()
            this.ready = true
          })
        }
      })
    },
    swipe() {
      this.pickedVenue = null
    },
    updatedFilter(filters) {
      this.extraFilters = filters
      this.debounceFetch()
    },
    getMarker(v) {
      let url = "pin-resto-small@2x.png"
      if (v.activeDeals && v.activeDeals.length) {
        url = "pin-resto-favoris-bonplan-small@2x.png"
      }
      if (v.activeMenus && v.activeMenus.length) {
        url = "pin-menu-bonplan-small@2x.png"
      }
      return {
        url: require(`@/assets/img/pins/${url}`),
        scaledSize: new google.maps.Size(20, 20), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
      }
      // return require(`./src/assets/img/pins/pin-menu-bonplan-small@2x.png`)
    },
    onMarkerClick($event, venue) {
      $event.domEvent.stopImmediatePropagation()
      $event.domEvent.preventDefault()
      this.pickedVenue = venue
    }
  }
}
</script>

<style lang="scss" scoped>
.venue {
  bottom: 0;
}
.swipe-enter-active,
.swipe-leave-active {
  transition: transform 0.2s;
}
.swipe-enter, .swipe-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}
</style>
